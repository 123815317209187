div.DraftEditor-root {
  background-color: #ffffff;
  font-size: 16px;
  font-family: "Roboto";
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 14px;
}
div.toolbar {
  position: relative;
  width: 80%;
  background-color: lightsteelblue;
  padding: 10px 20px;
  margin: auto;
  top: 45px;
  text-align: right;
}
div.toolbar button {
  border: 1px solid black;
  padding:5px;
  margin-right:4px;
  background-color:black;
  color:white;
}
/* div. {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 150px;
  margin-left: 80px;
} */
