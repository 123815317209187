.scroll {
   overflow-x: scroll;
   overflow-y: hidden;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   flex: 3;
}
.scroll::-webkit-scrollbar {
    width: 8px;
}

.scroll::-webkit-scrollbar-track {
    margin-right: 14px;
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
}
